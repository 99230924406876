@import "../../assets//css/variables.scss";

.brand-page {

        background-image: url('../../assets/images/Add-Brands-bg.jpg');
        background-size: cover;

}


.setup-country-dd .flag-dropdown {
        height: 36px !important;
        border-radius: 5px !important;
}

.react-tel-input .form-control {
        line-height: 25px;
        height: 34px;
}

.coutnryCls {
        line-height: 34px;
        border: 1px solid #d7cdcd;
        border-radius: 5px;
        width: 100%;
        padding-left: 50px;
}

.inputClass123 {
        display: none;
}
.setup-country-dd{
        .react-tel-input .flag-dropdown{
                top: -1px;
                left: 1px;
                border: 0;
        }
        .coutnryCls{border-color: #ddd;background-color: #fff;}
}