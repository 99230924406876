@import "../src/assets/css/style.scss";

.css-1jqq78o-placeholder {
    grid-area: 1 / 1 / 2 / 3;
    color: rgb(128, 128, 128);
    margin-left: 2px;
    margin-right: 2px;
    box-sizing: border-box;
    font-size: 14px !important;

}

#react-select-2-placeholder {
    font-size: 14px !important;
}


#react-select-1-input {
    font-size: 14px !important;
    min-width: 150px !important;
}

#react-select-2-input {
    font-size: 14px !important;
    min-width: 150px !important;
}

#react-select-3-input {
    font-size: 14px !important;
    min-width: 150px !important;
}

#react-select-4-input {
    font-size: 14px !important;
    min-width: 150px !important;
}

#react-select-5-input {
    font-size: 14px !important;
    min-width: 150px !important;
}

#react-select-6-input {
    font-size: 14px !important;
    min-width: 150px !important;
}

#react-select-7-input {
    font-size: 14px !important;
    min-width: 150px !important;
}

#react-select-8-input {
    font-size: 14px !important;
    min-width: 150px !important;
}

#react-select-9-input {
    min-width: 150px !important;
    font-size: 14px !important;
}

#react-select-10-input {
    font-size: 14px !important;
    min-width: 150px !important;
}
 

