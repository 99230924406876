@import "../../../../assets/css/variables.scss";

 
.add-rule-page{
.segment-main-wrap{
    display: flex;
   
    .conector{border-bottom:1px solid #DDE1EB ;
        width: 10px;
        display: flex;
        align-self: start;
        margin-top: 25px;
    }
    .seg-gate{
        background-color: #DFEAFE;
        border-radius: 30px;
        padding: 3px;
        display: flex;
        align-items: center;
        position: relative;
        min-height: 70px;
        justify-content: center;
        width: 27px;
        .plus-icon{
            background-color: #fff;
            color: $blueClr;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50px;
            height: 22px;
            width: 22px;
            box-shadow: 0px 3px 5px #0000002b;
            position: absolute;
            top: 4px;
            left: 2px;
        }
        .gate-name{
            transform: rotate(-90deg);
            -webkit-transform: rotate(-90deg);
            -moz-transform: rotate(-90deg);
            font-size: 10px;
            color: #000;
            display: inline-block;
            margin-top: 20px;
            white-space: nowrap;
        }
    }
    .control-card{
        border-radius: 10px;padding: 5px 7px;
        width: 100%;
        display: flex;
        gap: 10px;
        border: 1px solid #DDE1EB;
        margin-bottom: 15px;
       
        .form-select,.form-control{border-radius: 10px;
        font-size: 13px;
        font-weight: 500;
        padding: 8px 37px 8px 15px;
        width: auto;
        }
        .btn-delete{padding: 0;font-size: 18px;color: $red;margin-left: auto;margin-right: 7px;}
         
    }
}

.then-part{
    padding-left: 35px; 
    .seg-gate{
        background-color: #D8CFF8;
    }   
}
.helpdesk-page{
    .overview-card {
        border-radius: 10px;
        border: 1px solid var(--bdr-secondary, #F1F1F1);
        background: var(--base-white, #FFF);
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.05);
        padding: 14px;
    }
}
.has-error {

    border-color: #ca3406 !important;

}



.has-async-error>div {

    border-color: #ca3406 !important;

    border-radius: 10px;

} 

}
.ck.ck-editor__main>.ck-editor__editable:not(.ck-focused) {
    border-color: var(--ck-color-base-border);
    min-height: 275px !important;
 
}
.ck.ck-editor__main>.ck-editor__editable:not(.ck-blurred) {
    border-color: var(--ck-color-base-border);
    min-height: 275px !important;
 
}
 .progressbar{
    .bg1{
        background-color: #E95162;
    }
    .bg2{
        background-color: #ED974B;
    }
    .bg3{
        background-color: #EBE24F;
    }
    .bg4{
        background-color: #94D35B;
    }
    .bg5{
        background-color: #5EC798;
    }

    .text-1{
        color: #E95162;
    }
    .text-2{
        color: #ED974B;
    }
    .text-3{
        color: #EBE24F;
    }
    .text-4{
        color: #94D35B;
    }
    .text-5{
        color: #5EC798;
    }
 }