.mClass {
  cursor: pointer;
}

.colorText {
  color: #5a5aff !important;
}

.numberAlign {
  text-align: right !important;
  padding-right: 40px !important;
}

.action-hidden {
  display: block;
}