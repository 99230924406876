// @import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@300;400;500;600;700&display=swap');
@import "./variables.scss";

body {
    font-family: 'Manrope', sans-serif;
    font-size: 16px;
    font-weight: 500;
    overscroll-behavior: none;

}

.btn-outline-secondary {
    --bs-btn-border-color: #dee2e6 !important
}

.font-10 {
    font-size: 10px !important;
}

.font-12 {
    font-size: 12px !important;
}

.font-13 {
    font-size: 13px !important;
}

.font-14 {
    font-size: 14px;
}

.font-16 {
    font-size: 16px;
}

.font-18 {
    font-size: 18px;
}

.font-20 {
    font-size: 20px;
}

.font-22 {
    font-size: 22px;
}

.font-24 {
    font-size: 24px;
}

.font-28 {
    font-size: 28px;
}

a {
    text-decoration: none;
    transition: 0.4s;
}

a:hover {
    color: $blueClr !important;
}

.cursor-pointer {
    cursor: pointer;
}

.app-body {
    background: linear-gradient(180deg, #ECF5F2 0%, #EAF1FE 100%);
    min-height: calc(100vh - 87px);
    padding-bottom: 20px;
}

.text-brand {
    color: $blueClr !important;
}

.text-gray {
    color: $text-gray !important;
}

.text-success {
    color: $green !important;
}

.text-danger {
    color: $red !important;
}

.border-brand {
    border-color: $blueClr !important;
}

.bg-brand {
    background-color: $blueClr;
}

.bg-gradient-blue {
    background: $bg-blue-gradient;
}

.font-medium {
    font-weight: $font-medium;
}

.font-500 {
    font-weight: $font-500;
}

.font-400 {
    font-weight: $font-400;
}

.border-blue {
    border-color: $blueClr;
}

.border-gery{
    border-color:#aaa;
}

.btn-wmx {
    background: $bg-blue-gradient;
    color: #fff;
    font-size: 14px;
    font-weight: $font-500;
    height: 34px;
    border: 0;
    border-radius: 8px;
    padding: 7px 22px;
    line-height: 18px;
    white-space: nowrap;


    .icon {
        margin-right: 3px;
        vertical-align: sub;
        margin-left: -4px;
    }

    &.btn-lg {
        height: 40px
    }

}

a.btn-wmx:not(.btn-wmx-white) {
    display: inline-block;

    &:hover {
        color: #fff !important
    }

    &.btn-lg {
        height: 40px;
        line-height: 25px;
    }
}

.btn-wmx-gray {
    @extend .btn-wmx;
    background: #3A4961;
    border: 0;
}


.btn-wmx-light {
    @extend .btn-wmx;
    background: $LightBlue;
    color: $blueClr;
    border: 0;

    &:hover {
        background-color: #ffffff;
        color: $blueClr;
    }
}

.btn-wmx-white {
    @extend .btn-wmx;
    background: #ffffff;
    color: #344054;
    font-weight: 500;
    border: 1px solid #D0D5DD;
    line-height: 23px;

    &:hover {
        background-color: #ffffff;
        color: $blueClr !important;
    }
}

a.btn-wmx-light:hover {
    color: $blueClr !important;
}

.btn-sm {
    height: 28px;
    padding: 4px 15px;
    font-size: 13px;
}

.btn-soft-light {
    @extend .btn-wmx;
    background: #F2F2F2;
    color: #000;
    font-weight: $font-500;

    &:hover {
        background-color: #E9F1FF;
        color: $blueClr;
    }
}

.btn-light-outline {
    @extend .btn-soft-light;
    border: 1px solid #D0D5DD;
    line-height: 18px;

    &:hover {
        border-color: $blueClr;
    }
}

.button-group-light {
    gap: 5px;

    .btn {
        border-radius: 6px !important;

        &:hover {
            background-color: #fff;
            color: $blueClr;
        }
    }

    .btn-check:checked+.btn {
        background-color: #E9F1FF;
        color: $blueClr;
        border-color: #E9F1FF;
    }
}

.react-tel-input .form-control {
    position: relative;
    font-size: 14px;
    letter-spacing: .01rem;
    margin-top: 0 !important;
    // margin-bottom: 16px !important;
    margin-bottom: 0px !important;
    padding-left: 48px;
    margin-left: 0;
    background: #FFFFFF;
    border: 1px solid #CACACA;
    border-radius: 5px;
    line-height: 25px;
    height: 40px;
    width: 100% !important;
    outline: none;
}

.react-tel-input {
    font-family: 'Roboto', sans-serif;
    font-size: 15px;
    position: relative;
    width: 100%;
    // margin-bottom: 18px !important;
    margin-bottom: -2px !important;
    z-index: 3;
}

.object-cover {
    object-fit: cover;
}

.img-white {
    filter: brightness(0) invert(1);
}

.site-header {
    .header-nav {
        .v-devider {
            display: inline-block;
            width: 1px;
            background: #525252;
            height: 20px;
            margin: 0 15px;
        }
    }

    .profile-dd {
        .dropdown-menu {
            min-width: 200px;
            top: 45px;

            .dropdown-item:hover a,
            .dropdown-item:hover .icon {
                color: $blueClr !important;
            }
        }

        .icon {
            width: 18px;
            height: 18px;
            margin-right: 12px;
            color: #4b4b4b;
        }
    }

    .download-dd {
        .dropdown-menu {
            min-width: 300px;
        }
    }

    .sync-data-bar {
        .CircularProgressbar {
            .CircularProgressbar-text {
                font-size: 27px;
                fill: #f6981e;
            }

            .CircularProgressbar-path {
                stroke: #f6981e;
            }
        }

    }
}

.header-nav {
    padding-top: 15px;
    padding-bottom: 15px;
    background-color: #000;

    .navbar-nav {
        gap: 15px;
    }

    .nav-link,
    .nav-link.show {
        color: #fff;
        opacity: 0.5;
        font-size: 14px;
        font-weight: 500;
        padding: 7px;
        position: relative;
        transition: 0.4s;

        .icon {
            margin-right: 5px;
        }

        &:hover {
            color: #fff !important;
            opacity: 1;
        }

        &::after {
            content: '';
            height: 3px;
            width: 100%;
            position: absolute;
            left: 0;
            bottom: -26px;
            background: var(--gradient-bg, linear-gradient(52deg, #FF7526 0%, #FF0092 47.29%, #6F00FF 100%));
            opacity: 0;
        }
    }



    .dropdown-submenu-container {
        .dropdown-menu {
            top: auto;
            margin-top: -30px;
            left: 100%;
            margin-left: 1px;
            margin-right: auto;

            .dropdown-item {
                font-size: 14px;
                color: #4F5875;
                font-weight: $font-500;

            }
        }
    }

    .nav-dd-menu {
        .dropdown-toggle {
            background-color: transparent;
            padding: 7px;
            padding-right: 20px;
            border: 0;

            font-size: 14px;
            font-weight: 500;

            &:active {
                background-color: transparent;
            }

            &:hover {
                color: #fff;
            }

            &::after {
                border: 0;
            }

            .icon {
                margin-right: 5px;
            }
        }

        .nav-link {
            background-image: url('../images/down-arrow-white.svg');
            background-size: 10px;
            background-position: right 16px;
            background-repeat: no-repeat;

            &::before {
                content: '';
                background-size: 20px;
                width: 20px;
                height: 20px;
                position: absolute;
                left: 3px;
                background-repeat: no-repeat;
                background-position: center;
            }
        }

        .dropdown-item {
            padding-left: 15px !important;
        }
    }

    .nav-dd-menu>.dropdown-menu.show {
        top: 50px;
    }

    .nav-dd-menu.active {
        .nav-link {
            opacity: 1;

            &::after {
                opacity: 1;
            }
        }

    }

    .profile-dd {
        .dropdown-toggle {
            background: transparent;
            border: 0;
            padding: 0;
            font-size: 14px;
            font-weight: $font-500;

            &:hover {
                color: #868686;
            }

            &::after {
                border: 0;
            }
        }
    }

    // .campaigns-menu {
    //     .nav-link::before {
    //         content: '';
    //         background-image: url('../images/campaigns-icon.svg');
    //     }
    // }

    // .audience-menu {
    //     .nav-link::before {
    //         content: '';
    //         background-image: url('../images/icon-audience.svg');
    //     }
    // }

    // .insights-menu {
    //     .nav-link::before {
    //         content: '';
    //         background-image: url('../images/icon-insight.svg');
    //     }
    // }

    // .other-menu {
    //     .nav-link::before {
    //         content: '';
    //         background-image: url('../images/icon-setting.svg');
    //     }
    // }

    .dropdown-menu {
        box-shadow: 0px 2px 34px 0px rgba(0, 0, 0, 0.11);
        border-color: #ddd;

        .submenu-arrow {
            background-image: url('../images/right-arrow.svg');
            background-repeat: no-repeat;
            background-size: 6px;
            background-position: calc(100% - 10px);

        }
    }

    .nav-link.active {
        opacity: 1;
        color: #fff;

        &::after {
            opacity: 1;
        }
    }

    .notification-dd {
        .dropdown-toggle::after {
            border: 0;
        }
    }

    .profile-name {
        color: #CBCBCB;
    }
}

.dropd-noicon {
    .dropdown-toggle::after {
        border: 0;
    }
}

.form-control:focus,
.form-select:focus {
    box-shadow: none !important;
}

.form-check {
    .form-check-label {
        font-size: 14px;
        color: #424242;
    }

    .form-check-input:checked {
        background-color: $blueClr;
        border-color: $blueClr;
    }
}

.form-switch {
    .form-check-label {
        color: #1E1E1E;
    }
}

.page-breadcums {
    padding: 15px 13px;
    border-bottom: 1px solid #E9E9E9;
    margin-bottom: 15px;

    .breadcrumb {
        font-size: 12px;
        margin-bottom: 0;
        position: relative;
        z-index: 4;

        .breadcrumb-item a {
            color: #6E7C87;

            &:hover {
                color: $blueClr;
            }
        }

        .breadcrumb-item.active {
            color: #252C32;
        }
    }
}

.page-breadcums>.container-fluid {
    padding: 0;
}



.component-loader {
    background: #ffffffd4;
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(2px);
    z-index: 2;

    .loader-icon {
        width: 120px;
        height: 120px;
    }

    .loader {
        width: 80px;
        height: 80px;
        background-image: url('../../assets/images/webmaxyloader.svg');
        background-size: contain;

    }
}

.no-data-flound {
    width: 100%;
    height: 55vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
    padding: 15px;
    border-radius: 6px;
}

.page-title {
    font-size: 18px;
    font-weight: $font-500;
    margin-bottom: 0;
}

.btn-outline-light {
    border-radius: 8px;
    height: 34px;
    background-color: #fff;
    border-color: #D0D5DD;
    font-size: 14px;
    color: #344054;
    font-weight: $font-500;
    white-space: nowrap;
    padding: 5px 15px;

    &:active {
        background-color: #fff;
        color: #000;
    }

    &::after {
        border: 0;
    }
}

.btn-outline-blue {
    @extend .btn-outline-light;
    background-color: $LightBlue;
    border-color: $blueClr;
    color: $blueClr;

    .icon {
        vertical-align: sub;
    }
}

.fc {
    .fc-myButton-button {
        background: $bg-blue-gradient;
        color: #fff;
        border: 0 !important;
        font-size: 14px;
        font-weight: $font-500 !important;

        &:hover {}
    }

    .fc-toolbar-chunk {
        .btn-group {
            gap: 2px;

            .btn {
                text-transform: capitalize;
                font-size: 14px;
                font-weight: $font-500;
                color: $blueClr !important;
                background: $LightBlue !important;

                &.active {
                    background: $bg-blue-gradient !important;
                    color: #fff !important;
                }
            }
        }
    }

    .prevBtnCal,
    .nextBtnCal {
        @extend .fc-myButton-button;
        font-size: 16px !important;
        background: $LightBlue !important;
        color: $blueClr;
    }




    .fc-myButton-button {
        background: $bg-blue-gradient;
        color: #fff;
        border: 0 !important;
        font-size: 14px;
        font-weight: $font-500 !important;

        &:hover {}
    }

    .fc-toolbar-chunk {
        .btn-group {
            gap: 2px;

            .btn {
                text-transform: capitalize;
                font-size: 14px;
                font-weight: $font-500;
                color: $blueClr !important;
                background: $LightBlue !important;

                &.active {
                    background: $bg-blue-gradient !important;
                    color: #fff !important;
                }
            }
        }
    }

    .prevBtnCal,
    .nextBtnCal {
        @extend .fc-myButton-button;
        font-size: 16px !important;
        background: $LightBlue !important;
        color: $blueClr;
    }
}

.table-filtr-wrap {
    .table-heading {
        font-size: 18px;
        font-weight: $font-medium ;
        margin-bottom: 0
    }

    .btn-filter {
        @extend .btn-outline-light;

        .loader {
            border-color: $blueClr;
            border-bottom-color: transparent;
        }
    }

    #export.btn-filter svg,
    #next_btn.btn-filter svg,
    #saveTemp.btn-filter svg,
    .loader-btn svg {
        vertical-align: sub;
    }

    .btn {
        height: 38px
    }

    a.btn {
        line-height: 24px;
    }

}

.loader-btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.filter-dd {
    .dropdown-menu {
        width: 680px;
    }
}


.table-search-input {
    width: 250px;

    .form-control {
        height: 38px;
        border-color: #D0D5DD;
        padding-left: 0;
        border-radius: 0 8px 8px 0;
        border-left: 0;
        font-size: 15px;

        &::placeholder {
            color: $text-gray;
            font-size: 14px;
        }
    }

    .input-group-text {
        background: #fff;
        border-right: 0;
        border-color: #D0D5DD;
        border-radius: 8px 0 0 8px;
        height: 38px;
    }
}
.county-code-dd{
    .flag-dropdown,.selected-flag{width: 100% !important;max-width: 100%;background-color: #fff;}
    .form-control {width: 65px !important;}
}
.action-dd {
    .dropdown-toggle {
        background-color: transparent;
        padding: 0;
        border: 0;
        color: $text-gray;

        .icon {
            width: 25px;
            height: 25px;
        }

        &::after {
            border: 0;
        }

        &:active {
            background: transparent;
        }
    }
}

.table-wrap {
    min-height: 465px;
}

.sticky-table-shadow {
    border-collapse: separate;
    border-spacing: 0px;
}

.wmx-table-style {
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.05);

    thead th {
        font-size: 12px;
        color: $text-gray;
        font-weight: $font-500;
        background-color: #353535;
        color: #fff;
        white-space: nowrap;

        &:first-child {
            border-radius: 8px 0 0 0;
        }

        &:last-child {
            border-radius: 0 8px 0 0;
        }

        &.action-col {
            width: 60px;
        }
    }

    tbody {
        td {
            font-size: 14px;
            color: #101828;
            font-weight: $font-500;
            background-color: #fff;
        }

        tr:last-child {
            td {
                border-bottom: 0;
            }

            td:first-child {
                border-radius: 0px 0px 0px 8px;
            }

            td:last-child {
                border-radius: 0px 0px 8px 0px;
            }
        }
    }

    .imgIcon {
        height: 22px;
        // width: 25px;
    }
}

.badge {
    font-size: 12px;
    border-radius: 15px;
    padding: 5px 10px;
    font-weight: $font-500;
}

.success-soft {
    @extend .badge;
    background-color: #ECFDF3 !important;
    color: #027A48;
}

.disabled-soft {
    @extend .badge;
    background-color: #00000021 !important;
    color: #414141;
}

.danger-soft {
    @extend .badge;
    background-color: #FDF2FA !important;
    color: #C11574;
}

.warning-soft {
    @extend .badge;
    background-color: #FFF4D4 !important;
    color: #D06200;
}

.info-soft {
    @extend .badge;
    background-color: #EFF8FF !important;
    color: #175CD3;
}

.wmx-tab-dashboard {
    .nav {
        gap: 24px;
    }

    .nav-item {

        // background: var(--gradient-bg, linear-gradient(0deg, rgba(0, 0, 0, 0.05) 0%, rgba(0, 0, 0, 0.05) 100%), linear-gradient(52deg, #FF7526 0%, #FF0092 47.29%, #6F00FF 100%));
        .nav-link {
            color: #fff;
            font-size: 14px;
            font-weight: $font-500;
            border-radius: 0;
            border-bottom: 4px solid transparent;
            background-color: transparent;
            padding: 5px 0px;

            .icon {
                margin-right: 5px;
                opacity: 0.3;
            }

            &.active {
                border-bottom: 4px solid transparent;
                border-image: var(--gradient-bg, linear-gradient(52deg, #FF7526 0%, #FF0092 47.29%, #6F00FF 100%));
                border-image-slice: 1;

                .icon {
                    opacity: 1;
                }
            }
        }
    }
}

.productImage {
    height: 60px;
    width: 60px;
}

.table-no-scroll {
    overflow: inherit;
}

.table-pagination {
    .pagination {
        justify-content: space-between;

        .counts {
            display: flex;
        }

        .pagination-btn {
            .page-link {
                border-radius: 8px;
                height: 34px;
                background-color: #fff;
                border-color: #D0D5DD;
                font-size: 14px;
                color: #344054;
                font-weight: $font-500;
                white-space: nowrap;
                padding: 5px 15px;

            }
        }
    }

    .page-item {
        .page-link {
            font-size: 14px;
            color: $text-gray;
            background: transparent;
            border: 0;

            &:focus {
                box-shadow: none;
            }
        }
    }
}

.righSide-modal {
    .offcanvas-body {
        min-height: calc(100vh - 125px);
    }

    .offcanvas-header {
        background-color: #f5f5f5;
        color: #000;
        font-size: 16px;
        border-bottom: 1px solid #e6e6e6;

        .offcanvas-title {
            font-size: 16px;
        }
    }

    .offcanvas-footer {
        display: flex;
        justify-content: center;
        gap: 15px;
        padding: 15px 15px;
        box-shadow: 0px -2px 8px #0000000f;

        .btn {
            height: 40px;
            width: 50%;
        }
    }
}

.form-check-input:focus {
    box-shadow: none;
}

.form-check.check-minus {
    .form-check-input {
        background-image: url('../images/check-minus.svg');
        background-size: 8px;
    }
}

.offcanvas.size-md {
    width: 600px !important;
}

.offcanvas.size-xl {
    width: 800px !important;
}

.offcanvas.size-xxl {
    width: 950px !important;
}

.card {
    border-radius: 10px;
    border-color: #DDE1EB;

    .card-header {
        border-radius: 10px 10px 0 0;
    }
}

.wmx-form-style {
    display: contents;

    .form-floating>label {
        font-size: 14px;
        padding: 7px 13px;
        color: $text-gray;
    }

    .form-floating .form-control,
    .form-floating .form-select {
        height: 38px;
        min-height: 38px;
        padding: 6px 12px !important;
        font-size: 15px;
    }

    .form-floating>.form-control:focus~label,
    .form-floating>.form-control:not(:placeholder-shown)~label,
    .form-floating>.form-control-plaintext~label,
    .form-floating>.form-select~label {
        top: 0;
        width: fit-content;
        background: #fff;
        opacity: 1;
        height: 20px;
        padding: 0px 4px;
        left: 7px;
        font-size: 11px;
    }
}

.css-13cymwt-control {
    border-radius: 6px !important;
    font-size: 15px !important;
    border-color: #dee2e6 !important;
    color: #667085 !important;

}

.css-t3ipsp-control {
    box-shadow: none !important;

    &:hover {
        border-color: #ddd !important
    }
}

.has-error.form-floating {
    .form-control {
        border-color: #ca3406;
    }
}

.wmx-normal-form {
    .form-select {
        height: 34px;
        font-size: 13px;
        padding: 6px 11px;
        line-height: 20px;
        font-weight: $font-500;
    }
}

.dropdown {
    .dropdown-menu {
        .dropdown-item {
            font-size: 14px;
            font-weight: $font-500;
            padding: 7px 14px;
        }
    }

    .dropdown-menu.show {
        border-radius: 8px;
        border: 1px solid #DDE1EB;
        background: var(--base-white, #FFF);
        box-shadow: 0px 2px 34px 0px rgba(0, 0, 0, 0.11);
    }
}

.text-dd {
    background: transparent;
    border: 0;
    color: #000;
    padding: 0;

    &:hover,
    &.show,
    &:active {
        background-color: transparent !important
    }
}

.no-icon.dropdown-toggle {
    &::after {
        border: 0;
    }

    &::before {
        border: 0;
    }
}

.steps-wizard {
    .step {
        display: flex;
        background-color: #fff;
        border: 1px solid #E4E4E4;
        padding: 12px 11px;
        align-items: center;
        position: relative;
        width: 120px;
        height: 40px;
        line-height: 40px;
        margin-bottom: 30px;
        text-align: center;
        color: #fff;

        &::after {
            content: "";
            position: absolute;
            right: -20px;
            top: 0;
            border-top: 20px solid transparent;
            border-bottom: 20px solid transparent;
            border-left: 20px solid #fff;
            z-index: 1;
        }
    }

    .step-no {
        display: flex;
        width: 22px;
        height: 22px;
        text-align: center;
        font-size: 12px;
        padding: 4px;
        background-color: #EBEBEB;
        border-radius: 50px;
        color: #000;
        align-items: center;
        justify-content: center;
        margin-right: 5px;
    }

    .step-name {
        font-size: 14px;
    }

    #flowBoxes {
        margin: auto;
        min-width: 100%;
        display: flex;

        .arrow-shape {
            display: inline-block;
            position: relative;
            height: 34px;
            line-height: 22px;
            padding: 5px 24px;
            border: 1px solid #ccc;
            margin-right: 5px;
            background-color: white;
            font-size: 14px;

            &.right:after {
                content: '';
                border-top: 1px solid #ccc;
                border-right: 1px solid #ccc;
                width: 24px;
                height: 24px;
                position: absolute;
                right: -2px;
                top: 0px;
                background-color: white;
                z-index: 3;

                -webkit-transform: translate(10px, 4px) rotate(45deg);
                -moz-transform: translate(10px, 4px) rotate(45deg);
                -ms-transform: translate(10px, 4px) rotate(45deg);
                -o-transform: translate(10px, 4px) rotate(20deg);
                transform: translate(10px, 4px) rotate(45deg);
            }

            &.left:before {
                content: '';
                border-top: 1px solid #ccc;
                border-right: 1px solid #ccc;
                width: 24px;
                height: 24px;
                position: absolute;
                top: 0px;
                background-color: #ebf4f5;
                z-index: 2;
                left: -2px;

                -webkit-transform: translate(-10px, 4px) rotate(45deg);
                -moz-transform: translate(-10px, 4px) rotate(45deg);
                -ms-transform: translate(-10px, 4px) rotate(45deg);
                -o-transform: translate(-10px, 4px) rotate(20deg);
                transform: translate(-10px, 4px) rotate(45deg);
            }
        }

        .arrow-shape:first-child {
            padding-left: 10px;
        }

        .active {
            background-color: #E9F1FF;
            color: $blueClr;
            border-color: $blueClr;

            .step-no {
                background-color: $blueClr;
                color: #fff;
            }

            &::before {
                border-color: $blueClr !important;
            }
        }

        .arrow-shape.active:after {
            background-color: #E9F1FF;
            border-color: $blueClr;
        }
    }
}

.wmx-tab-style-1 {
    .nav {
        gap: 8px;
    }

    .nav {
        border: 1px solid #cecece;
        background-color: #fff;
        padding: 3px 3px;
        border-radius: 7px;
        width: fit-content;
    }

    .nav-link {
        background-color: #ffffff;
        font-size: 14px;
        line-height: 14px;
        color: #000;
        border: 1px solid #e4e2e2;
        padding: 7px 17px;
    }

    .nav-link.active {
        background-color: #E9F1FF !important;
        color: $blueClr;
        border-color: $blueClr;
    }
}

.wmx-tab-style-2 {
    @extend .wmx-tab-style-1;

    .nav-link {
        border: 0;
        background-color: #eaecf1;

        .icon {
            margin-right: 4px;
        }
    }

    .nav-link.active {
        background-color: $LightBlue !important;
        color: $blueClr;
    }

    &.dark {
        .nav-link.active {
            background-color: $blueClr !important;
            color: #fff;

            .icon {
                color: #fff !important;
            }

            &:hover {
                color: #fff !important;
            }
        }
    }
}

.switch-view-btn-group {
    border: 1px solid #D0D5DD !important;
    border-radius: 6px;
    padding: 3px;

    .btn {
        background-color: #fff;
        color: #344054;
        border: 0;
        font-size: 14px;
        padding: 2px 11px;
        border-radius: 4px !important;
        height: 30px;

        &:hover {
            background-color: #E9F1FF;
            color: $blueClr;
        }
    }

    .btn-check:checked+.btn {
        background-color: $blueClr;
        color: #fff;
    }
}

.rdrDateRangePickerWrapper {
    border-radius: 10px;
    box-shadow: 0px 3px 12px #0000002b;

    .rdrStaticRange {
        border-radius: 10px 0 0 0;
    }

    .rdrDayActive {
        .rdrDayNumber {
            color: $blueClr;
        }
    }

    .rdrDay {

        .rdrInRange,
        .rdrEndEdge {
            color: $blueClr !important;
        }
    }

    .rdrDayStartOfMonth,
    .rdrDayToday {
        .rdrStartEdge {
            color: $blueClr !important;
        }
    }

    .rdrDay .rdrStartEdge {
        color: $blueClr !important;
    }

    .rdrDateDisplayWrapper {
        border-radius: 0 10px 0 0;
    }

    .rdrDefinedRangesWrapper {
        border-radius: 10px 0 0 10px;
    }

    .rdrDateRangeWrapper {
        border-radius: 0 10px 10px 0;
    }

    .rdrMonthsHorizontal {
        border-radius: 0 0 10px 0;
    }
}

.dashboard-header-sec {
    background-image: url('../../assets//images/dasboard-header-bg.jpg');
    background: linear-gradient(264.51deg, #FECB14 6.28%, #FE3388 44%, #A639B5 60.41%, #2A53E9 87.89%);
    background-position: center;
    background-size: cover;
    background-color: #414141;
    padding: 20px 0 90px 0;

    .title {
        color: #fff;
        font-size: 22px;
        font-weight: $font-medium;
        margin-bottom: 0;
    }

}

.dashboard-date-range {
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 8px;

    .icon {
        vertical-align: sub;
    }
}


.dashboard-card {
    border-radius: 10px;

    .card-header {
        border-radius: 10px 10px 0 0;
        border: 0;
        background-color: #fff;
        padding-bottom: 0;
        padding-top: 20px;
    }
}

.emoji_com {
    position: absolute;
    top: 10 !important;
    margin-top: -25px;
    right: 20px;
    z-index: 1;
}

.ProductImgIcon {
    height: 60px;
    width: 60px;
    border-radius: 10px;
}

.emoji-position_com {
    position: absolute;
    position: absolute;
    right: 12px;
    top: 176px;
    background: none;
    border: none;
}

.text-blue {
    color: rgb(13, 110, 253)
}

.access-permission-table {
    .access-permison-card {
        .card-header {
            font-size: 15px;
        }

        .second-leve-root {
            .form-check {
                margin-left: 6px;
            }
        }

        .card-body {
            font-size: 15px;
            font-weight: $font-400;
            padding: 10px 12px;
            border-bottom: 1px solid #ddd;

            .collapse-icon {
                cursor: pointer;
                color: #b6b6b6;
            }
        }

        .second-leve-root .brand-col {
            padding-left: 25px;
        }

        .third-leve-root .brand-col {
            padding-left: 58px;
        }

        .forth-leve-root .brand-col {
            padding-left: 85px;
        }

        .form-check {
            font-size: 18px;
        }

        .center-checkbox {
            display: flex;
            justify-content: center;
        }

        .collapse-icon[aria-expanded="true"] svg {
            -webkit-transform: rotate(45deg);
            -moz-transform: rotate(90deg);
            -ms-transform: rotate(90deg);
            -o-transform: rotate(90deg);
            transform: rotate(90deg);
        }
    }
}

.css-1nmdiq5-menu {
    z-index: 99999 !important;
}

.minWidth {
    min-width: 943px !important;
}

.btn-wmx-primary {
    @extend .btn-wmx;
    font-weight: 400;
    background: $blueClr;
}

.btn-wmx-secondary {
    @extend .btn-wmx;
    font-weight: 400;
    background: $lightGreen;
}

.bg-wmx-secondary {
    background-color: #ecedf1 !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

#wbDateRangPicker .mClass {
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 8px;
}

.rdrDateDisplayItemActive {
    border-color: #6f00ff;
}

.rdrDateDisplayItemActive input {
    color: #466bf3;
    font-weight: 500;
}

.text-wrap {
    font-size: 14px;
    word-wrap: break-word;
    width: 71%;
}

.css-1fdsijx-ValueContainer {
    font-size: 14px !important;
    color: #808090 !important;
}

.css-1nmdiq5-menu {
    font-size: 14px !important;
}

.wmx-tab-style-1 {
    overflow-x: auto;
}

.rdrStaticRange:hover .rdrStaticRangeLabel,
.rdrStaticRange:focus .rdrStaticRangeLabel {
    border-radius: 10px;
}

@media (max-width: 767px) {
    .header-logo {
        width: 70px;
    }

}

@media (max-height: 700px) {

    .verticle-menu .menu-list .dropdown-toggle,
    .verticle-menu .menu-list .menu-item {
        margin-bottom: 3px !important;
    }
}

.onboarding-btn {
    position: fixed;
    left: 88px;
    bottom: 10px;
    z-index: 99;

    .dropdown-menu {
        width: 384px;
        padding-top: 0px;
        padding-bottom: 15px;
    }

    .bg-brand.dropdown-item {
        border-radius: 9px 9px 0px 0px;
        padding-top: 15px;
        padding-bottom: 15px;
        margin-bottom: 8px;
    }

    .dropdown-header:hover {
        background-color: $blueClr;
    }

    .btn {
        font-weight: $font-medium;

        &::after {
            border: 0;
        }

        .count {
            background-color: #FFC46D;
            color: $blueClr;
            width: 18px;
            height: 18px;
            border-radius: 30px;
            display: inline-flex;
            justify-content: center;
            font-size: 12px;
            font-weight: 700;
        }
    }
}

.number-connect {
    width: 25px !important;
    float: left;
    text-align: center;
    color: #fff;

}

.businessapi {
    li {
        font-size: 13px;
    }

    h6 {
        font-size: 14px;
        font-weight: bold;
    }
}

.whatsappbg {
    background-image: url('../images/whatsapp.png');
    width: 220px;
    height: 470px;
    background-repeat: no-repeat;
    margin: 0 auto;
}

/* CSS talk bubble */

/* Right triangle placed top left flush. */
.tri-right.border.left-top:before {
    content: ' ';
    position: absolute;
    width: 0;
    height: 0;
    left: -40px;
    right: auto;
    top: -8px;
    bottom: auto;
    border: 32px solid;
    border-color: #666 transparent transparent transparent;
}

.tri-right.left-top:after {
    content: ' ';
    position: absolute;
    width: 0;
    height: 0;
    left: -14px;
    right: auto;
    top: 0px;
    bottom: auto;
    border: 14px solid;
    border-color: #fff transparent transparent transparent;
}

.talk-bubble {
    margin: 20px;
    display: inline-block;
    position: relative;
    width: 188px;
    height: auto;
    background-color: #fff;
    margin-top: 19px;
    border-radius: 7px;
    padding: 3px;
    margin-bottom: 0px;

    p {
        font-size: 12px;
        padding-top: 10px;
        padding-left: 10px;
        margin-bottom: 6px;
        margin-top: -3px;
    }
}

// Whatsapp Playground css
.playground {
    .screen-active {
        background-color: #ebf3f8;
        border-radius: 5px;
    }

    .screen {
        background-color: #fff;
        border-radius: 5px;
    }

}

.accordion-screen {
    button {
        padding: 9px;
        font-size: 14px;
        font-weight: 600;
        gap: 5px;
        padding-top: 12px;
        padding-bottom: 12px;
    }

    .accordion-button::after {
        background-size: 16px;
    }
}

.accordion-screen {
    .accordion-collapse.collapse.show {
        background-color: #ebf3f9;
        border-radius: 0px 0px 5px 5px;
    }

    .accordion-button:not(.collapsed) {
        background-color: #ebf3f9;
    }

    .accordion-button:focus {
        box-shadow: none;
    }

    .accordion-button {
        border-radius: 5px;
    }

    .accordion-body {
        padding: 10px;
    }

    .textleght {
        position: absolute;
        right: 10px;
        top: 6px;
    }

    .input95 {
        width: 94%;
    }

    .buttonsize {
        .btn {
            padding-top: 2px;
        }
    }
}

.Item_item__azkIp:hover>div {
    flex-direction: row !important;
}

.Dropdown_dropdown__ZbOaq .Dropdown_button__HvVDU {
    font-size: 14px !important;
    letter-spacing: 0px !important;

}

.positiondropdown {
    position: absolute;
}

.talk-popup {
    margin: 1px;
    display: inline-block;
    position: relative;
    width: 218px;
    height: 446px;
    background-color: #fff;
    margin-top: 19px;
    border-radius: 13px;
    padding: 3px;
    margin-bottom: 1px;
}

.show-preview {
    bottom: 2px;
    position: absolute;
    transition: all 0.3s ease-out;
    background: #0000005c;
    border-radius: 13px;
}


.feedback {
    font-size: 11px;
}

.wouldyou {
    padding-top: 0px;
    padding-bottom: 0px;

    h4 {
        font-size: 13px;
        font-weight: 600;
    }

    small {
        font-size: 11px;
    }

    .textarea-style {
        font-size: 12px;
        font-weight: 500;
    }

    .text-right {
        text-align: right;
    }

    button {
        width: 100%;
        font-size: 12px;
        font-weight: 600;
    }

    .scrollsty {
        overflow-y: scroll;
        height: 348px;
        margin-bottom: 5px;
        padding-left: 5px;
        padding-right: 5px;
        overflow-x: hidden;
    }
}

.seprator {
    margin-bottom: 9px;
    margin-top: 10px;
}

.chatbg {
    background-image: url('../images/fram.png');
    width: 220px;
    height: 470px;
    background-repeat: no-repeat;
    margin: 0 auto;
    overflow: hidden;
    box-shadow: rgba(99, 99, 99, .2) 0 2px 8px 0;
    border-radius: 15px;

    .previewflow {
        position: absolute;
        top: 135px;
        color: #2196F3 !important;
        width: 112px;
        text-align: center;
        font-size: 11px !important;
        cursor: pointer;

    }
}

.msg-preview {
    bottom: -53vh;
    position: absolute;
    transition: all 0.3s ease-out;

    .talk-popup {}

    &.show-preview {
        .talk-popup {
            top: 0;
            transition: all .3s ease-out;
        }
    }
}

.feedback-dropdown {
    .dropdown-toggle {
        background: transparent;
        border: transparent;
        color: #000;
        padding: 0px;
    }

    .dropdown-toggle::after {
        display: none;
    }

    .dropdown-50 {
        min-width: 100px;
        padding-top: 4px;
        padding-bottom: 4px;
    }

    .dropdown-item {
        padding-top: 5px !important;
        padding-bottom: 5px !important;
    }
}

.Neon {
    font-family: sans-serif;
    font-size: 14px;
    color: #494949;
    position: relative;


}

.Neon * {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.Neon-input-dragDrop {
    display: block;
    width: 100%;
    margin: 0 auto 25px auto;
    padding: 25px;
    color: #8d9499;
    color: #97A1A8;
    background: #fff;
    border: 2px dashed #C8CBCE;
    text-align: center;
    -webkit-transition: box-shadow 0.3s, border-color 0.3s;
    -moz-transition: box-shadow 0.3s, border-color 0.3s;
    transition: box-shadow 0.3s, border-color 0.3s;
}

.Neon-input-dragDrop .Neon-input-icon {
    font-size: 48px;
    margin-top: -10px;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.Neon-input-text h3 {
    margin: 0;
    font-size: 14px;
    color: #000;
}

.Neon-input-text span {
    font-size: 12px;
}

.Neon-input-choose-btn.blue {
    color: #008BFF;
    border: 1px solid #008BFF;
}

.Neon-input-choose-btn {
    display: inline-block;
    padding: 8px 14px;
    outline: none;
    cursor: pointer;
    text-decoration: none;
    text-align: center;
    white-space: nowrap;
    font-size: 12px;
    font-weight: bold;
    color: #8d9496;
    border-radius: 3px;
    border: 1px solid #c6c6c6;
    vertical-align: middle;
    background-color: #fff;
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.05);
    -webkit-transition: all 0.2s;
    -moz-transition: all 0.2s;
    transition: all 0.2s;
}

.uploadinput {
    z-index: 999;
    opacity: 0;
    width: 320px;
    height: 145px;
    position: absolute;
    right: 0px;
    left: 0px;
    margin-right: auto;
    margin-left: auto;
}

.spanupload {
    display: inline-block;
    margin: 15px 0
}

.directRow {
    flex-direction: row
}

.menuconfig-bg{
    background-color: #fcfcfc;
    border-radius: 10px;
    padding: 10px;
    min-height: 351px;
}

.addbannerbutton{
    background-color: #000000;
    padding: 3px;
    color: #fff;
    border-radius: 10px;
    display: inline-block;
    float: right;
    padding-left: 10px;
    padding-right: 10px;
}
.mainbackgroundbg{
    background-color: #DDEDF3;
}

.addborderdot{
    background: #FFFFFF;
border: 1.2px dashed #382C7D;
border-radius: 8px;
width: 120px;
height: 120px;
display: flex;
align-items: center;
justify-content: center;
}

.heightadjust{
    height: 233px;
}

.addbackground {
    margin-top: -10px;
    margin-right: 19px;
}
.cursor-pointer{
    cursor: pointer;
}
.menucontainer{
    font-size: 14px;
}
.inputw {
    margin: 0.4rem;
  }

  .active-background{
    background-image: url('../images/kpi/mainback.jpg');
  }

  .uploadimageborder{
    border: 1px dashed #2D56EB;
border-radius: 8px;
  }

  .addphotoafter {
    background: #FFFFFF;
    border: 1.2px dashed #382C7D;
    border-radius: 8px;
    width: 100%;
    height: 132px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.menucontainer .col-lg-4.text-center{
    cursor: pointer;

}
.editdelete {
    position: absolute;
    right: 6px;
    z-index: 99;
}
.editdelete button {
    background-color: transparent;
    border: none;
    color: #fff;
}

.editdelete .btn.show{
    background-color: transparent;
    color: #fff;
}

.editdelete .btn:hover{
    background-color: transparent;
    border: none;
    color: #fff;
}
.editdelete .dropdown-toggle::after{
    display: none;
}

.editdelete .dropdown-menu{
    min-width: 102px;
}
.addbackgroundmin {
    margin-top: 0px;
    margin-right: 19px;
    position: absolute;
    right: 0;
    z-index: 9;
}
.heightadjust1{
    height: 270px;
    object-fit: contain;
}
.carousel-control-next{
    width: 6%;
}
.carousel-control-prev, .carousel-control-next{
    width: 6%;
}

.addbanner{
    position: absolute;
    right: 0;
    z-index: 1;
}
.container-wrapper {
    width: 650px;
}
.menuiconheight{
    height: 119px;
    border-radius: 8px;
}
.background-cover{
    background-size: cover;
}

/* Radio button css here */ 

.form-check-input[type=radio] {
    background: url(../images/radio-dot-hover.svg) !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
    background-size: 16px !important;
    cursor: pointer !important;  
}
.form-check-input:checked[type=radio] {
 background: url(../images/radio-dot.svg) !important;
 background-repeat: no-repeat !important;
 background-position: center !important;
 background-size: 16px !important;
 }