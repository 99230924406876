$blueClr: #466BF3;
$DarkBlueClr: #223FC3;
$LightBlue : #E9F1FF;
$font-medium: 600;
$font-500: 500; 
$font-400: 400; 
$bg-blue-gradient: linear-gradient(90deg, #466BF3 0%, #2A53E9 100%);
$text-gray: #667085;
$green: #00c800;
$lightGreen: #50BF91;
$red : #F14D34;