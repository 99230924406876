
button .loader {
    width: 20px;
    height: 20px;
    border: 3px solid #FFF;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
    margin-right: 14px;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.blur {
    box-shadow: 0px 0px 20px 20px rgba(255,255,255,1);
    text-shadow: 0px 0px 10px rgba(51, 51, 51, 0.9);
    transform: scale(0.9);
    opacity: 0.6;
}
