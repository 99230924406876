@import "../../../assets/css/variables.scss";

.customeCardHight {
  min-height: 250px !important;
}

.ailoader {
  width: 200px;
  height: 200px;
  background-image: url("../../../assets/images/ailoader.gif") !important;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}



.bg-image-page {
  .upload-drag-file {
    border: 1px dashed #333;
    padding: 12px;
    border-radius: 8px;
    min-height: 230px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: 0.4s;

    &:hover {
      border-color: $blueClr;
      border-style: solid;
    }
  }

  .choose-img-from-button {
    .btn-group {
      gap: 20px;
      width: 100%;
    }

    .btn {
      background: #F8F9FB;
      border-color: #E4E4E4;
      color: #000;
      border-radius: 8px !important;
      padding: 12px 14px;
    }

    .btn-check:checked+.btn {
      background: $bg-blue-gradient !important;
      border-color: $blueClr;
      color: #fff;
    }
  }


}

.offcanvas-body {
  .search-header {
    position: sticky;
    top: 0;
    z-index: 1;
  }  
}

.masonry-grid {
  .flexbox {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    height: 58vw;
    gap: 15px;


    .item {
      position: relative;
      width: 48.20%;
      border-radius: 8px;  
      border: 1px solid transparent;  

      &:hover {
        cursor: pointer;
        box-shadow: 0px 0px 18px #0000003b;
        border-color: $blueClr;
      }

      img {
        width: 100%;
        display: block;
        transition: all .8s;
        border-radius: 8px;
        padding: 3px;
      }

      .img-type {
        position: absolute;
        bottom: 10px;
        left: 10px;
        padding: 4px 8px;
        font-size: 11px;
        text-shadow: 0 0 8px rgba(0, 0, 0, 0.42);
        display: inline-block;
        background: #545454;
        text-transform: uppercase;
        color: #fff;
        border-radius: 5px;

        &.free {
          background-color: #579e00;
        }
      }

    }
  }


  @media (max-width : 860px) {

    .flexbox {
      height: 220vw;

      .item {
        width: 50%;
      }
    }
  }

  @media (max-width : 667px) {

    .flexbox {
      height: auto;

      .item {
        width: 100%;
      }
    }
  }
}


.ai-generated-card-page {
  .ai-generated-card {
    &.selected {
      border-color: $blueClr;
    }

    .template-img {
      height: 370px;
      width: 100%;
      object-fit: contain;
    }

    .btn {
      padding: 4px 6px;
      height: auto;
      line-height: 18px;

      .icon {
        font-size: 18px;
      }

      &:hover:not(.btn-wmx) {
        background-color: #fff;
        color: #000;
      }
    }

    .icon1 {
      color: #c038a9;
    }

    .icon2 {
      color: #000;
    }

    .icon3 {
      color: #fff;
    }

    .btn-check:checked+.btn {
      border-color: #D0D5DD;

      .icon {
        color: #c038a9;
      }

    }
  }

}


.select-size-page {
  .card {
    transition: 0.3s;
    cursor: pointer;

    &:hover {
      border-color: $blueClr;
    }
  }

  .card.selected {
    border-color: $blueClr;
  }
}

.creative-ai-page{
  .select-size-card:hover{border-color: $blueClr;}
}




.create-rule-page {
  .segment-main-wrap {
    display: flex;

    .conector {
      border-bottom: 1px solid #DDE1EB;
      width: 10px;
      display: flex;
      align-self: start;
      margin-top: 25px;
    }

    .seg-gate {
      background-color: #DFEAFE;
      border-radius: 30px;
      padding: 3px;
      display: flex;
      align-items: center;
      position: relative;
      min-height: 70px;
      justify-content: center;
      width: 27px;

      .plus-icon {
        background-color: #fff;
        color: $blueClr;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50px;
        height: 22px;
        width: 22px;
        box-shadow: 0px 3px 5px #0000002b;
        position: absolute;
        top: 4px;
        left: 2px;
      }

      .gate-name {
        transform: rotate(-90deg);
        -webkit-transform: rotate(-90deg);
        -moz-transform: rotate(-90deg);
        font-size: 10px;
        color: #000;
        display: inline-block;
        margin-top: 20px;
        white-space: nowrap;
      }
    }

    .control-card {
      border-radius: 10px;
      padding: 10px 12px;
      width: 100%;
      display: flex;
      gap: 10px;
      background-color: #fff;
      border: 1px solid #DDE1EB;
      box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.15);
      margin-bottom: 15px;
      display: flex;
      justify-content: space-between;

      .form-select,
      .form-control {
        border-radius: 10px;
        font-size: 13px;
        font-weight: 500;
        padding: 8px 37px 8px 15px;
        width: auto;
      }

      .btn-delete {
        padding: 0;
        font-size: 18px;
        color: red;
        margin-left: auto;
        margin-right: 7px;
      }

    }
  }

}

.ai-content-page {
  .example-card {
    width: 100%;
    background-color: #fff;
    border-radius: 10px;
    .title {
      font-size: 12px;
      font-weight: $font-medium;
    }
    .sub-title {
      font-size: 14px;
      font-weight: $font-medium;
      color: #000;
    }
    .side-btn {
      font-size: 12px;
      color: #fff;
      font-weight: $font-500;
      border-radius: 0px 30px 30px 0;
      padding: 4px 7px;
    }
    .shape-wrap {
      width: 100%;
      .right-corner-shape {
        background-color: #ddd;
        height: 160px;
        border-radius: 113px 0 0 0;
      }
    }
  }
}