.preview {
    /* background-color: lightgray; */
    min-height: 160px;
    height: 200px !important;
    background-color: #ecedf1 !important;
    overflow-y: auto;
}

/* 
.bg-secondary-gray{
    min-height: 160px;
    background-color: #ecedf1 !important;
    overflow-y: auto;
}
 */
.label-text {
    text-align: right;
}

.text-message{
    text-align: right;
}
.time-class{
    position: absolute;
    bottom: 6px;
    left: -2%;
    font-size: 12px;
    text-align: right;
    padding-right: 2px;
}
.text-wrap-space {
    white-space: break-spaces;
}