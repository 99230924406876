.email-gatway-page{
    .email-gatway-card{
      min-height: 170px;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: 0.3s;
      cursor: pointer;
      .logo{
        width: 170px;
        height: 65px;
        object-fit: contain;
    
      }
    
    }
    .card-footer{
      position: absolute;
        bottom: 0;
        width: 100%;
        background-color: #FCDCD2;
        border: 0;
        color: #F24E1E;
        font-size: 14px;
        padding: 5px 15px;
    }
    
    }
    .div-font{
        
        color: #1e0969;
        font-size: 16px;
        padding: 5px 15px;
        width: 100%;
        font-family:sans-serif
      
    }