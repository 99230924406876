@import "../../assets/css/variables.scss";

.basicInfo-page {
    .profile-bg {
        background-image: url('../../assets/images/profile-bg.png');
        border-color: $blueClr !important;
        background-size: cover;
    }

    .profile-tab {
        .nav-item .icon {
            font-size: 18px;
        }
    }
}


.setting-page{
    .table-wrap {
        min-height: 380px;
    }
}



    .profile-img {
        display: inline-block;
        position: relative;
        width: fit-content;
        margin: 0 auto;

        .edit-option {
            display: inline-block;
            background: #fff;
            width: 30px;
            height: 31px;
            border-radius: 30px;
            padding: 2px 2px;
            position: absolute;
            bottom: 6px;
            right: -12px;
        }
    }

    .form-switch{
        .form-check-input{
            margin-top: 6px;
        }
    }
